<template>
  <v-navigation-drawer v-model="show" :scrim="false">
    <slot name="premenu"></slot>
    <v-list density="compact" nav open-strategy="independent">
      <template v-for="(item, i) in authorized_menuitems(menu)">
        <v-divider v-if="item.divider" :key="i"></v-divider>
        <v-list-group
          v-if="item.children && !item.disabled"
          :prepend-icon="item.icon"
          :key="item.name"
          no-action
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              @click="fill"
              :ref="(e) => (refs[item.name] = e)"
              :title="$t(item.name)"
              v-bind="props"
            >
              <v-tooltip
                activator="parent"
                location="bottom"
                v-if="ellipsed[item.name]"
              >
                {{ $t(item.name) }}
              </v-tooltip>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(submenu, i) in authorized_menuitems(item.children)"
            :id="submenu.name"
            :ref="(e) => (refs[submenu.name] = e)"
            :key="i"
            link
            :active="submenu.path === $route.path"
            :prepend-icon="submenu.icon"
            :title="$t(submenu.name)"
            :value="$t(submenu.name)"
            :to="submenu.path"
            :disabled="submenu.disabled"
            @click="menuActionClick(submenu.action)"
            :icon="submenu.icon"
          >
            <v-tooltip
              v-if="ellipsed[submenu.name]"
              activator="parent"
              location="bottom"
              >{{ $t(submenu.name) }}</v-tooltip
            >
          </v-list-item>
        </v-list-group>
        <v-list-item
          :prepend-icon="item.icon"
          :title="$t(item.name)"
          :value="$t(item.name)"
          :ref="(e) => (refs[item.name] = e)"
          link
          v-else
          :active="item.path === $route.path"
          :key="item.title"
          :disabled="item.disabled"
          :to="item.path"
          @click="menuActionClick(item.action)"
          :id="item.name"
        >
          <v-tooltip
            v-if="ellipsed[item.name]"
            activator="parent"
            location="bottom"
            >{{ $t(item.name) }}</v-tooltip
          ></v-list-item
        >
      </template>
    </v-list>
    <slot name="postmenu"></slot>
  </v-navigation-drawer>
</template>
<script setup>
import { useDisplay } from "vuetify/lib/framework.mjs";

const { hasRight } = useAuth();
const { mdAndUp, smAndDown } = useDisplay();
const props = defineProps({
  items: {
    default: () => [],
    type: Array,
  },
});

const route = useRoute();
const { locale } = useI18n();
const refs = ref({});
const show = ref(mdAndUp.value);
const menu = computed(() => {
  return props.items.filter((x) => x.sidebar !== true);
});

const ellipsed = ref({});
function fill() {
  setTimeout(() => {
    Object.keys(refs.value).forEach((e) => {
      ellipsed.value[e] = isEllipsed(e);
    });
  }, 50);
}

watch([refs, locale], fill, { deep: true });

onMounted(fill);
onUpdated(fill);

function isEllipsed(e) {
  var wrapper = refs.value[e]?.$el;
  if (!wrapper) return;
  var limit = wrapper.querySelector(".v-list-item__append");
  wrapper = wrapper.querySelector(".v-list-item__content");
  var el = wrapper.querySelector(".v-list-item-title");
  if (!el) return;
  //console.log("wrapper" , wrapper, el, el.offsetWidth, wrapper.offsetWidth, wrapper.clientWidth, wrapper.scrollWidth + " - " + wrapper.scrollHeight ,  "el" ,   el.scrollWidth + " - " + el.scrollHeight, el.innerText.length, limit)
  if (limit)
    return wrapper.scrollWidth < el.scrollWidth || el.innerText.length > 16;
  else return wrapper.scrollWidth < el.scrollWidth;
}

function authorized_menuitems(items) {
  return items.filter((x) =>
    x.children
      ? x.children.find((t) => hasRight(t)) && hasRight(x)
      : hasRight(x)
  );
}

function toggle() {
  show.value = !show.value;
}
function menuActionClick(url) {
  if (url) {
    window.open(url, "_blank");
  }
}

defineExpose({
  toggle,
});
</script>
<style scoped>
.v-list-group__items .v-list-item {
  padding-inline-start: 2em !important;
}

.active {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
